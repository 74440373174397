<template>
  <v-container v-if="getClassroom(courseCode)">
    <v-row>
      <v-col cols="12" md="12">
        <h1>出欠を表示中</h1>
      </v-col>
      <v-col
        cols="12"
        xm="12"
        sm="6"
        md="6"
        v-for="c in getClassrooms(courseCode)"
        :key="c.courseCode"
        ><Classroom
          :numberOf="1"
          :classrooms="[c]"
          view="register"
          :inActive="false"
        />
      </v-col>
      <v-col cols="12" xm="12" sm="6" md="6">
        <v-card class="mx-auto" elevation="8" :height="cardHeight()">
          <v-list-item three-line class="my-0 py-0">
            <v-list-item-content class="md-0">
              <v-list-item-title class="headline mb-1">
                <p>{{ getClassroom(courseCode).name }}</p>
              </v-list-item-title>
              <v-list-item-subtitle>
                各種操作(Additional operation)
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions class="ma-4 pa-2">
            <v-btn :to="`/attendance`" color="primary">戻る(Return)</v-btn>
          </v-card-actions>
          <v-card-actions class="ma-4 pa-2" v-if="hasCoordinatorPermission">
            <v-dialog v-if="true" v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" color="accent" dark v-bind="attrs" v-on="on"
                  ><span v-if="true">授業日追加</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  <span>授業日の追加</span>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    閉じる
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <AddDateForm
                    :classroom="getClassroom(courseCode)"
                    @dialog="dialog = false"
                    mode="add"
                  />
                </v-card-text>
                <v-divider></v-divider>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="550px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-right">
              開講日(Date)
            </th>
            <th class="text-center" v-for="(day, i) in days" :key="i">
              {{ day.date.getMonth() + 1 }}/{{ day.date.getDate() }}({{
                day.period
              }})
            </th>
          </tr>
          <tr>
            <th class="text-right">
              曜日(Day of the week)
            </th>
            <th class="text-center" v-for="(day, i) in days" :key="i">
              <v-btn
                class="mx-0 weekdayText"
                depressed
                rounded
                x-small
                :color="getWeekdayColor(day.date.getDay())"
                :to="getDateDetailPath(day)"
                >{{ getWeekdayName(day.date.getDay()) }}
              </v-btn>
            </th>
          </tr>
          <tr>
            <td class="text-right">
              詳細(Detail)
            </td>
            <td class="text-center" v-for="(day, i) in days" :key="i">
              <v-btn x-small :to="getDateDetailPath(day)">{{
                getAdditionJa(day)
              }}</v-btn>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(student, i) in activeStudents"
            :key="`${student.uid}-${i}`"
          >
            <th>
              <router-link :to="getStudentPath(student.uid)">{{
                `${i + 1}: ${student.getName}`
              }}</router-link>
            </th>
            <td
              class="text-center"
              v-for="(day, j) in days"
              :key="`${day.date}-${j}`"
            >
              <Status :status="getStatus(day, student)" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h3 class="mt-8">無効化された学生</h3>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-right">
              開講日(Date)
            </th>
            <th class="text-center" v-for="(day, i) in days" :key="i">
              {{ day.date.getMonth() + 1 }}/{{ day.date.getDate() }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(student, i) in disabledStudents"
            :key="`${student.uid}-${i}`"
          >
            <th>
              <router-link :to="getStudentPath(student.uid)">{{
                `${i + 1}: ${student.getName}`
              }}</router-link>
            </th>
            <td
              class="text-center"
              v-for="(day, j) in days"
              :key="`${day.date}-${j}`"
            >
              <Status :status="getStatus(day, student)" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>
<script>
import Status from "./Status";
import Classroom from "../Classroom";
import AddDateForm from "./AddDate";

export default {
  name: "Table",
  props: ["courseCode"],
  components: {
    Status,
    Classroom,
    AddDateForm
  },
  data: () => ({
    dialog: false,
    statuses: [
      "attended",
      "late",
      "left",
      "late-left",
      "others",
      "absence",
      "alert",
      "undefined",
      "canceled"
    ],
    weekdays: [
      { id: 1, name: "月曜(Mo)" },
      { id: 2, name: "火曜(Tu)" },
      { id: 3, name: "水曜(We)" },
      { id: 4, name: "木曜(Th)" },
      { id: 5, name: "金曜(Fr)" },
      { id: 6, name: "土曜(Sa)" },
      { id: 0, name: "日曜(Su)" }
      //   { id: 0, name: "その他" }
    ]
  }),
  computed: {
    activeStudents() {
      return this.$store.state.studentsAttendance
        .filter(s => {
          return s.active;
        })
        .slice()
        .sort((a, b) => {
          return a.sortedName < b.sortedName ? -1 : 1;
        });
    },
    disabledStudents() {
      return this.$store.state.studentsAttendance
        .filter(s => {
          return !s.active;
        })
        .slice()
        .sort((a, b) => {
          return a.sortedName < b.sortedName ? -1 : 1;
        });
    },
    days() {
      return this.$store.state.attendanceDates.slice().sort((a, b) => {
        return a.sortedDate - b.sortedDate;
      });
    },
    hasCoordinatorPermission() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    cardHeight() {
      return this.$store.state.cardHeight;
    },
    getDateDetailPath(day) {
      const attendanceId = day.attendanceId;
      return `/attendance/${this.courseCode}/date/${attendanceId}`;
    },
    getAdditionJa(day) {
      return day.addition ? "補講(A)" : "通常(N)";
    },
    getStudentPath(studentId) {
      return `/attendance/${this.courseCode}/student/${studentId}`;
    },
    getWeekdayColor(weekdayId) {
      if (weekdayId == 1) {
        return "#779438";
      } else if (weekdayId == 2) {
        return "#007d7a";
      } else if (weekdayId == 3) {
        return "#055a8e";
      } else if (weekdayId == 4) {
        return "#772f6d";
      } else if (weekdayId == 5) {
        return "#a7374a";
      } else {
        return "#000";
      }
    },
    getClassrooms(courseCode) {
      const results = this.$store.state.classrooms.filter(c => {
        return c.courseCode == courseCode || c.parentCourseCode == courseCode;
      });
      return results;
    },
    getClassroom(courseCode) {
      const result = this.$store.state.classrooms.filter(
        c => c.courseCode == courseCode
      );
      if (result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    getWeekdayName(weekdayId) {
      const week = this.weekdays.filter(w => {
        return w.id == weekdayId;
      });
      if (week.length > 0) {
        return week[0].name;
      }
      return "";
    },
    getStatus(day, student) {
      const studentId = student.uid;

      const statusIndex = day.attendanceStatus.findIndex(a => {
        return a.studentId === studentId;
      });
      if (day.canceled) {
        return "canceled";
      } else if (statusIndex > -1) {
        const StatusObj = day.attendanceStatus[statusIndex];
        const status = StatusObj.status;
        const late = StatusObj.late;
        const left = StatusObj.left;
        if (status === 1) {
          if (late && left) {
            return "late-left";
          } else if (late) {
            return "late";
          } else if (left) {
            return "left";
          } else {
            return "attended";
          }
        } else if (status === 2) {
          return "absence";
        } else if (status === null) {
          return "undefined";
        } else {
          return "alert";
        }
      }
      if (student.active) {
        return "undefined";
      } else {
        return null;
      }
    }
  }
};
</script>
<style scoped>
.weekdayText {
  color: #fff;
}
thead th {
  z-index: 120 !important;
  background-color: #fff;
}
tbody th:first-child {
  /* 横スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 100;
  min-width: 200px;
  background-color: #fff;
}
thead th:first-child {
  z-index: 120;
}
</style>
